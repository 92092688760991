<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('firstName')"
          type="text"
          v-model="item.profile.firstName"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('lastName')"
          type="text"
          v-model="item.profile.lastName"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('email')"
          type="text"
          v-model="item.email"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'SpeakerFilter',
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    }
  },
  methods: {}
};
</script>
