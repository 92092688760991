<template>
  <div class="speaker-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('Speakers')"/>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <SpeakerFilterForm :values="filters" ref="filterForm" slot="filter"/>
          </DataFilter>
          <br/>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template slot="item.firstName" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'firstName') }}
            </template>

            <template slot="item.lastName" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'lastName') }}
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-show="() => showHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import SpeakerFilterForm from '../../components/speaker/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: 'SpeakerList',
  servicePrefix: 'Speaker',
  mixins: [ListMixin, ProfileMixin],
  components: {
    Toolbar,
    ActionCell,
    SpeakerFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        {text: this.$i18n.t('firstName'), value: 'firstName'},
        {text: this.$i18n.t('lastName'), value: 'lastName'},
        {text: this.$i18n.t('email'), value: 'email'},
        {text: this.$i18n.t('enabled'), value: 'enabled'},
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      selected: [],
      filters: {
        profile: {}
      }
    };
  },
  computed: {
    ...mapGetters('speaker', {
      items: 'list'
    }),
    ...mapFields('speaker', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('speaker', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      clearSpeakerData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {}
      };
    },

    refreshData(){
      this.clearSpeakerData();
      this.clearProfileData();
      this.onSendFilter();
    }
  }
};
</script>
